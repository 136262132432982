import request from './config'

/**
 * 新增排除字段
 */
export function addRelation (params) {
  return request.post('/fields', params)
}
/**
 * 修改排除字段
 */
export function changeRelation (fieldId, params) {
  return request.put(`/fields/${fieldId}`, params)
}
/**
 * 删除排除字段
 */
export function delRelation (fieldId) {
  return request.delete(`/fields/${fieldId}`)
}

// 查询分类组
export function getClassifyGroup (id) {
  return request.get(`/fields/group/category/${id}`)
}

// 查询字段组
export function getRelationGroup (params) {
  return request.get('/fields/group', { params })
}

// 新增分类组
export function addRelationGroup (params) {
  return request.post('/fields/group/category', params)
}

// 查询所有字段
export function getAllRelation () {
  return request.get('/fields')
}

// 查询用户字段
export function getUserRelation (params) {
  return request.get(`/fields/userRelation/${params}`)
}
// 修改/新增 用户字段

export function editUserRelation (params, fieldId) {
  return request.post(`/fields/userRelation/${params}`, fieldId)
}

// 查询指点字段组下详情
export function getRelationDetail (params) {
  return request.get(`/fields/${params}`)
}

// 新增字段组（只能admin使用）
export function addFieldGroup (params) {
  return request.post('/fields/group', params)
}

// 修改字段组（只能admin使用）
export function changeFieldGroup (groupId, params) {
  return request.put(`/fields/group/${groupId}`, params)
}

// 删除字段组（只能admin使用）
export function delFieldGroup (groupId, params) {
  return request.delete(`/fields/group/${groupId}`, params)
}

// 修改字段（只能admin使用）
export function changeField (fieldId, params) {
  return request.put(`/fields/${fieldId}`, params)
}
// 删除字段（只能admin使用）
export function delField (fieldId) {
  return request.delete(`/fields/${fieldId}`)
}

// 修改入职展示状态
export function changeEntryShow (fieldId, params) {
  return request.put(`/fields/show/${fieldId}?show=${params.show}`)
}

// 字段排序
export function sortField (fieldId) {
  return request.put(`/fields/sort/${fieldId}`)
}

// 查询字段用户
export function getFieldUser (fieldId) {
  return request.get(`/fields/users/${fieldId}`)
}

// 查询所有用户
export function getAllUsersField (params) {
  return request.get('/adminUser/noPage/list', { params })
}

// 设置字段用户
export function changeFieldUser (fieldId, params) {
  return request.put(`/fields/users/${fieldId}`, params)
}

// 移动字段
export function moveField (fieldId, parentFieldId) {
  return request.put(`/fields/moveFields/${fieldId}`, parentFieldId)
}
