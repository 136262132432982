<template>
  <el-dialog
    :title="`新增模块`"
    :visible.sync="show"
    width="550px"
    :before-close="handleClose"
    append-to-body
  >
    <el-form label-width="80px">
      <el-form-item label="分类组">
        <el-select
          size="medium"
          v-model="selectPlate"
          filterable
          style="width: 300px; margin-left: 20px"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in allPlateList"
            :key="item.id"
            :label="item.typeName"
            :value="item.id"
          >
            <span>{{ item.typeName }}</span>
            <img
              :src="item.img"
              style="height: 30px; width: 30px; border-radius: 25px"
            />
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getRelationGroup, addRelationGroup } from '@/api/field'
export default {
  name: 'edit',
  props: {
    show: Boolean,
    book: Object,
    categoryId: [Number, String]
  },
  data () {
    return {
      form: {},
      allPlateList: null,
      selectPlate: null,
      bigImg: {
        src: '',
        isShow: false
      }
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        getRelationGroup().then((res) => {
          this.allPlateList = res
        })
      }
    }
  },
  methods: {
    handleSubmit () {
      if (this.selectPlate && this.categoryId) {
        addRelationGroup({
          fieldGroupId: this.selectPlate,
          categoryId: this.categoryId
        }).then((res) => {
          this.$message.success('保存成功')
          this.handleClose()
          this.$emit('refresh')
        })
      } else {
        this.$message.warning('请选择分类组')
      }
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.tips {
  font-size: 12px;
  color: #999;
}
.el-select-dropdown__item {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-box {
  position: relative;
}
#imgDetail {
  position: absolute;
  z-index: 999999;
  max-width: 500px;
  left: 0;
  top: 0;
}
</style>
