<template>
  <el-container class="height-calc-type1">
    <aside-category
      :cateType="4"
      @asideSelect="handleSelect"
      @asideToEdit="toCategoryEdit"
      ref="_asideCategory"
    ></aside-category>
    <el-container>
      <el-main class="main-cont box-shadow-light-grey border-radius-6">
        <div class="book-header">
          <el-button @click="getList">刷新数据</el-button>
          <el-button
            class="new-book margin-left-twentyFour"
            type="primary"
            @click="toEdit(null)"
            style="margin-bottom: 15px"
            >新增板块</el-button
          >
        </div>
        <el-table
          v-loading="loading"
          :data="dataList"
          height="calc(100vh - 312px)"
          style="width: 100%"
        >
          <el-table-column prop="id" label="字段组id" width="100">
          </el-table-column>
          <el-table-column prop="dataType" label="数据类型"> </el-table-column>
          <el-table-column prop="typeName" label="类型名称"> </el-table-column>
          <el-table-column prop="img" label="图片">
            <template slot-scope="scope">
              <img
                :src="scope.row.img"
                alt=""
                style="width: 50px; height: 50px"
                @mouseenter="showImgDetail($event, scope.row.img)"
                @mouseleave="hideImgDetail()"
              />
            </template>
          </el-table-column>
        </el-table>
        <edit
          :show="isEdit"
          :book="current"
          :categoryId="Number(activeIndex)"
          @close="isEdit = false"
          @refresh="handlePageChange(1)"
        />
      </el-main>
      <el-drawer
        :visible.sync="isCategoryEdit"
        :title="`${editForm.id ? '编辑' : '新增'}`"
        size="450px"
      >
        <!-- <el-dialog
        :title="`${editForm.id ? '编辑' : '新增'}分类`"
        :visible.sync="isCategoryEdit"
        width="450px"
        :before-close="() => (this.isCategoryEdit = false)"
      > -->
        <el-form label-width="60px">
          <el-form-item label="名称">
            <el-input v-model="editForm.name" placeholder="分类名称" />
          </el-form-item>
          <el-form-item label="类别">
            <el-select
              v-model="editForm.type"
              style="width: 100%"
              :clearable="false"
              disabled
            >
              <el-option label="客服消息" :value="1"></el-option>
              <el-option label="巨量引擎" :value="2"></el-option>
              <el-option label="书籍管理" :value="3"></el-option>
              <el-option label="板块管理" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="isCategoryEdit = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmitCategoryEdit"
            >确 定</el-button
          >
        </div>
      </el-drawer>
    </el-container>
    <img id="imgDetail" v-show="bigImg.isShow" :src="bigImg.src" alt="" />
  </el-container>
</template>

<script>
import { getClassifyList, editClassify } from "@/api/classify";
import { getClassifyGroup } from "@/api/field";
import Edit from "./edit";
import AsideCategory from "../category";
export default {
  name: "index",
  data() {
    return {
      show: true,
      search: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      current: null,
      isEdit: false,
      isCategoryEdit: false,
      showAccount: false,
      activeIndex: null,
      editForm: {
        type: 4,
        name: "",
      },
      type: "service",
      classifyList: null,
      allBookClassify: "",
      bigImg: {
        src: "",
        isShow: false,
      },
    };
  },
  computed: {
    classifyName: function () {
      if (this.classifyList) {
        return this.classifyList;
      }
      return "undefined";
    },
    showClassCol: function () {
      return !this.activeIndex;
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
    activeIndex(newVal) {
      this.search = null;
      this.handlePageChange(1);
    },
  },
  methods: {
    handleAccountShow(data) {
      this.current = data;
      this.showAccount = true;
    },
    toEdit(data) {
      this.current = data;
      this.isEdit = true;
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getClassifyGroup(this.activeIndex)
        .then((res) => {
          this.dataList = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.search = "";
      this.$emit("close");
    },
    handleSelect(item) {
      item < 0 ? (this.activeIndex = null) : (this.activeIndex = item);
      sessionStorage.setItem("classify", item);
    },
    toCategoryEdit(data, type = 4) {
      if (data) {
        this.editForm = {
          type: data.type,
          name: data.name,
          id: data.id,
        };
      } else {
        this.editForm = {
          type,
          name: "",
        };
      }
      this.isCategoryEdit = true;
    },
    // 新增分类
    handleSubmitCategoryEdit() {
      if (this.editForm.name) {
        editClassify(this.editForm).then((res) => {
          if (this.type === "service") {
            this.$refs._asideCategory.init();
          } else {
            // this.getOceanClassify();
          }
          this.$message.success("保存成功");
          this.isCategoryEdit = false;
        });
      } else {
        this.$message.error("请输入分类名称");
      }
    },
    showImgDetail(event, src) {
      this.bigImg.src = src;
      this.bigImg.isShow = true;
      const img = document.getElementById("imgDetail");
      // const targetX = 20 + event.clientX - img.offsetWidth / 2;
      // const targetY = 20 + event.clientY - img.offsetHeight / 2;
      const targetX = event.clientX - 600;
      const targetY = document.body.clientHeight / 2 - 100;
      img.style.left = targetX + "px";
      img.style.top = targetY + "px";
    },
    hideImgDetail() {
      this.bigImg.isShow = false;
    },
  },
  components: {
    Edit,
    // AccountList,
    AsideCategory,
  },
  created() {
    getClassifyList({
      type: 4,
    })
      .then((res) => {
        this.classifyList = res;
        this.activeIndex = `${res[0].id}`;
      })
      .finally(() => (this.loading = false));
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  box-sizing: border-box;
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.el-header {
  padding: 0;
  margin: 0 16px;
}
.book-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-bottom: 20px;
  .el-button.new-book {
    margin-bottom: 0 !important;
  }
}
.el-main .main-cont {
  margin-left: 16px;
  background-color: #fff;
}
.el-table__body-wrapper {
  height: 100%;
}
.largeImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#imgDetail {
  position: absolute;
  z-index: 999999;
  max-width: 500px;
  left: 0;
  top: 0;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
